@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

body {
  font-family: "Lato", sans-serif;
  font-size: 1.15rem;
  font-weight: 300;
}
h1,
h2,
h3,
h4 {
  color: #447969;
  line-height: 1em;
  margin: 1.5rem 0;
  font-family: "Lato", serif;
  font-weight: 700;
}
.panel-heading p {
  color: #447969;
  line-height: 1em;
  margin: 0 0;
  font-size: 1.45rem;
  font-family: "Lato", serif;
  font-weight: 400;
}
h1 {
  font-size: 46px;
}
h2 {
  margin: 1.5rem 0 0.5rem;
}
h2 span {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}
.display-1 {
  font-family: "Lato", serif;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
  font-size: 65px;
  line-height: 1em;
  text-align: left;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  padding-top: 110px;
  letter-spacing: -1px;
}
.display-1-less {
  padding-top: 80px;
}
.small-header {
  font-size: 45px;
}
.small-header span {
  font-weight: 400;
  font-size: 40px;
}
.panel-body .row {
  margin-left: 20px !important;
}
.padding-button {
  padding-top: 36px;
}
.header {
  background-image: url(../images/header-main-new.jpg);
  background-size: cover;
  padding-bottom: 60px;
}
.header-int {
  background-image: url(../images/header-main-new-short.jpg);
  background-size: cover;
  padding-bottom: 60px;
}

.header.diamondheadBanner {
  background-image: url(../images/header-main-new.jpg);
}

.header.iao-valleyBanner {
  background-image: url(../images/iao-valley-main.jpg);
}

.header.waianapanapaBanner {
  background-image: url(../images/waianapanapa-main.jpg);
}

.header-int.diamondheadBanner {
  background-image: url(../images/header-main-new-short.jpg);
}

.header-int.iao-valleyBanner {
  background-image: url(../images/iao-valley-short.jpg);
}

.header-int.waianapanapaBanner {
  background-image: url(../images/waianapanapa-short.jpg);
}

.reservation {
  background: rgba(255, 255, 255, 0.85);
  padding: 3px;
  margin: 0 5px;
}
.brown-header {
  background: rgba(112, 91, 53, 0.67);
  margin-bottom: 20px;
  padding: 0px 25px;
}
.brown-header h2 {
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
  margin: 1.1rem 0;
}
.block {
  background: #000000 !important;
}
.future {
  color: #9c3e3e;
  font-weight: bold;
}
h2.display-3 {
  color: #ffffff;
  font-size: 80px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
h2.display-small {
  color: #ffffff;
  font-size: 60px;
  text-align: left;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
p,
label {
  font-family: "Lato", serif;
  font-size: 18px;
  line-height: 27px;
  margin: 1.2rem 0 1.2rem;
}
.large {
  font-size: 21px;
  line-height: 32px;
}
label {
  margin: 1.2rem 0 0.4rem;
}
.row-padding {
  padding: 50px 0;
}
.row-padding-top {
  padding-top: 50px;
}
.row-padding-top-less {
  padding-top: 25px;
}
.row-padding-bottom {
  padding-bottom: 50px;
}
.row-right-left-padding {
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 5px;
}
.text-center img {
  text-align: center;
  margin: 0 auto;
}
.ShowArrow {
  display: none;
}
.showMobile {
  display: none;
}
.smaller-text {
  font-size: 17px;
  line-height: 26px;
}
label {
  margin-top: 0.3rem;
}
a {
  color: #447969;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
  color: #447969;
}
.smaller-text p {
  font-size: 14px;
  line-height: 20px;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid #447969;
}
.red {
  color: #ce1515;
}
.navbar {
  justify-content: center;
}
.navbar-brand {
  padding: 25px 0px 15px;
}
.header-repeater {
  background-color: rgb(112, 91, 53, 0.77);
  background-position-y: bottom;
  background-size: 45px 9px;
  min-height: 90px;
}
.dnlr {
  background-color: rgb(112, 91, 53, 1);
  background-position-y: bottom;
  background-size: 15px 9px;
  min-height: 20px;
}
.link-top {
  padding: 50px;
  font-size: 16px;
}
.link-top a {
  color: #ffffff;
}
.pic-header-1-full {
  background: url(../images/header-main.jpg);
  background-size: cover;
  margin-bottom: 0rem;
  width: 100%;
  float: left;
  height: 204px;
}
.pic-header-1 {
  background: url(../images/header-pic-1.jpg);
  background-size: cover;
  margin-bottom: 0rem;
  width: 65%;
  float: left;
  height: 464px;
  border-right: 3px solid #ffffff;
}
.pic-header-2 {
  background: url(../images/header-pic-2.jpg);
  background-size: cover;
  margin-bottom: 0rem;
  width: 35%;
  float: right;
  height: 232px;
  border-bottom: 3px solid #ffffff;
}
.pic-header-3 {
  background: url(../images/header-pic-3.jpg);
  background-size: cover;
  margin-bottom: 0rem;
  width: 35%;
  float: right;
  height: 232px;
}
.callouts-home {
  background-size: cover;
  height: 260px;
}
.callouts-home h3 {
  color: #ffffff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 30% 5%;
  line-height: 1.1em;
  text-decoration: none;
}
a .callouts-home {
  text-decoration: none !important;
}
.blue {
  background: #447969;
  height: 10px;
  padding: 0px;
  margin-bottom: 0.1rem;
  clear: both;
}
.light-blue {
  background: #bdd4db;
}
.light-yellow {
  background: #feefce;
}
.padding-yellow {
  padding: 5px 30px 10px;
}
.padding {
  padding: 5px 20px;
}
.callout-dashboard {
  padding: 25px 20px;
}
.less-column-padding {
  padding-right: 5px;
  padding-left: 5px;
}
.login-padding {
  padding-top: 40px;
  text-align: right;
}
.btn-primary,
.page-item.active .page-link {
  background: #447969;
  border-color: #447969 !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;
  background-color: #396a5b !important;
  border-color: #396a5b !important;
}
.btn-outline-primary,
.page-item:first-child .page-link,
.page-link {
  color: #447969;
  border-color: #447969;
  background: #ffffff;
}
.time {
  color: #000000;
  border: 1px solid #447969 !important;
  font-size: 1.15rem;
  font-weight: 300;
  background: rgba(110, 166, 154, 0.7);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.705rem 1.25rem 0.805rem !important;
}
.booked {
  color: #90aba3;
  border: 1px solid #90aba3 !important;
  font-size: 1.15rem;
  font-weight: 300;
  background: rgba(110, 166, 154, 0.3);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.705rem 1.25rem 0.805rem !important;
}
.highlight {
  background: #e9edec;
  padding-bottom: 0rem !important;
}
.input-group-addon {
  border-right: 1px solid #447969 !important;
  border-top: 1px solid #447969 !important;
  border-bottom: 1px solid #447969 !important;
  background: rgba(110, 166, 154, 0.7);
  padding: 0.405rem 0.65rem 0 !important;
}
.quantity-dropdown {
  background-image: url(../images/arrow.svg) !important;
  background-position: calc(100% - 15px) 16px;
  background-size: 20px auto;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Lato", serif;
  font-weight: 300 !important;
  color: #000;
  border: 1px solid #447969;
  border-radius: 0rem;
  font-size: 1.15rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
  padding: 0.375rem 2.55rem 0.375rem 0.85rem;
  height: calc(1.5em + 0.95rem + 2px);
  background-color: #ffffff;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  border-color: #f79a16;
  background: none;
}
.btn {
  border: 2px solid #f79a16;
  border-radius: 0px;
  padding: 0.505rem 1.25rem 0.605rem;
  font-family: "Lato", sans-serif;
  text-decoration: none;
  font-size: 1.15rem;
}
.btn-lg {
  padding: 0.8rem 2.25rem 0.905rem;
  font-size: 26px !important;
  font-weight: 700;
  font-size: 20px;
}
.btn-sm {
  padding: 0.405rem 1.25rem 0.405rem;
  font-size: 16px !important;
  font-weight: 400;
  font-size: 20px;
}
.btn-x-lg {
  padding: 0.6rem 2.95rem 0.755rem;
  font-size: 22px;
  margin-top: 15px;
}
.no-outline {
  border: 1px solid transparent !important;
}
.dropdown-toggle {
  padding-right: 15px;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item {
  color: #447969;
  text-decoration: none;
}
.bi-chevron-down {
  margin-right: -5px;
  margin-left: 5px;
}
.btn-reverse {
  background: #ffffff;
  margin: 10px;
  color: #447969;
}
.btn-reverse-outline {
  border: 1px solid #ffffff;
  margin: 10px;
  color: #ffffff;
}

/*******************FORM*******************/
form p {
  margin-bottom: 0rem;
}
.form-control,
input {
  font-family: "Lato", serif;
  font-weight: 300 !important;
  color: #000;
  border: 1px solid #447969;
  border-radius: 0rem;
  font-size: 1.15rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
  padding: 0.375rem 0.35rem;
  height: calc(1.5em + 0.95rem + 2px);
}
.form-control option {
  font-weight: 300 !important;
}
.dropdown-toggle::after {
  display: none;
}
select {
  background-image: url(../images/arrow.svg) !important;
  background-position: calc(100% - 15px) 16px;
  background-size: 20px auto;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.form-check-input {
  padding-right: 3rem;
  -webkit-box-shadow: 0px;
  -moz-box-shadow: 0px;
  box-shadow: 0px;
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  margin-top: -2px;
}
.form-check-label {
  margin-bottom: 0;
  margin: 7px 8px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.5rem;
}
.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0.25rem;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0.55rem;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.form-check-inline-row {
  float: left;
}

/*******************VENDOR*******************/

.item-padding p {
  padding-top: 65px;
  padding-bottom: 30px;
  margin-top: 0px;
}
.item-select {
  padding-top: 60px;
  padding-bottom: 60px;
}
.item-padding-less p {
  padding-top: 8px;
  padding-bottom: 0px;
  margin: 0px;
}
.item-select-less {
  padding-top: 5px;
  padding-bottom: 5px;
}
.item-padding-less-top p {
  padding-top: 5px;
  padding-bottom: 10px;
}
.line-bottom {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #bdd4db;
}
.line-bottom-dark {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #447969;
}
p.less-padding {
  margin: 0.5rem 0;
}
.line-top-light {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #447969;
}
.line-bottom-light {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dde5ac;
}
.line-top {
  padding-top: 1.5rem;
  margin-top: 1rem;
  border-top: 1px solid #447969;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 0.9;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #447969;
  border: 1px solid #447969;
  border-radius: 0rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.input-group-text-smaller {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 0.9;
  color: #447969;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #447969;
  border-right: 0px;
  border-radius: 0rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
a.select-popup {
  width: 100%;
  display: inline-block;
  padding: 0.1rem 0.2rem;
}
a.select-popup:hover {
  background: rgba(110, 166, 154, 0.7);
}
.modal-header {
  padding: 2rem 3rem 0rem;
  border-bottom: 0px;
}
.modal-body {
  padding: 1rem 3rem;
}
.modal-body p:first-child {
  margin-top: 0px;
}
.modal-footer {
  padding: 0rem 3rem 3rem;
  border-top: 0px;
}
.choose-size-btn {
  background-color: #ffffff;
  border: 2px solid #447969;
  color: #447969;
  width: 100%;
  padding: 0.3rem 0.8rem;
}
.choose-category-on {
  background-color: #ffffff;
  border: 1px solid #447969;
  color: #447969;
  padding: 0.3rem 0.8rem;
}
.choose-category {
  background-color: #ffffff;
  color: #447969;
  padding: 0.3rem 0.8rem;
  border: 1px solid #ffffff;
}
.choose-size {
  background-color: #ffffff;
  color: #447969;
  padding: 0.5rem 1.2rem;
  border: 2px solid #447969;
}
.buttons-cart {
  padding: 10px 0;
}
.buttons-cart a {
  float: right;
  margin: 8px 20px 0 0;
}
.text-end {
  text-align: right !important;
}
.text-start {
  text-align: left !important;
}
.paragraph-no-margin p {
  margin-bottom: 0px;
}
.paragraph-no-margin input {
  margin-bottom: -10px;
}
.modal-dialog {
  color: #000000;
}
.iti {
  position: relative;
  display: block !important;
}
/*******************FOOTER*******************/
footer {
  padding: 30px 0 0px;
  color: #ffffff;
  background-color: #3b2f04;
}
.sub-footer {
  padding: 25px 0 10px;
  color: #ffffff;
  background: #705b35;
}
.sub-footer p,
footer p {
  font-size: 15px;
  font-family: "Lato", serif;
  margin-top: 0rem;
}
.sub-footer a,
.sub-footer a:hover,
footer a,
footer a:hover {
  color: #ffffff;
  text-decoration: none;
}
.sub-footer .btn,
footer .btn {
  background: #d64d9c;
  padding: 0.9rem 2.95rem 1.155rem;
  font-size: 20px;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .navbar-brand {
    padding: 20px 0px 20px;
    display: inline-block;
  }
  .display-1 {
    max-width: 960px;
    font-size: 54px;
  }
}
@media only screen and (max-width: 922px) {
  .login-padding {
    padding-top: 20px;
    text-align: right;
  }
  .display-1 {
    max-width: 720px;
    font-size: 54px;
    padding-top: 180px;
  }
  .pic-header-1 {
    width: 100%;
    float: left;
    height: 364px;
    border-right: 0px;
    border-bottom: 3px solid #ffffff;
  }
  .pic-header-2 {
    width: 50%;
    float: left;
    height: 162px;
    border-right: 3px solid #ffffff;
    border-bottom: 0px;
  }
  .pic-header-3 {
    width: 50%;
    float: right;
    height: 162px;
  }
}
@media only screen and (max-width: 768px) {
  .less-column-padding {
    padding-right: 15px;
    padding-left: 15px;
  }
  .smaller-text p {
    padding: 0px;
    margin: 0px;
  }
  .display-1 {
    max-width: 540px;
    font-size: 44px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    margin-top: -20px;
  }
  h1 {
    font-size: 40px;
  }
  .no-border {
    border-bottom: 0px;
  }
  footer .col-md-8,
  footer .col-md-4 {
    width: 100%;
    flex-basis: 100%;
  }
  p {
    font-size: 17px;
    line-height: 1.4em;
  }
  .text-left-mobile {
    text-align: left !important;
  }
  .Break {
    display: block;
  }
  .addPadding {
    margin: 10px 0 0 0;
  }
  .Hide {
    display: none;
  }
  .showMobile {
    display: inline-block;
  }
  footer .container {
    flex-wrap: wrap;
    text-align: center;
  }
  footer p,
  .sub-footer p {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    padding: 0px;
    margin: 10px;
    text-align: center !important;
  }
  footer p.text-end {
    text-align: center !important;
  }
  .text-left-mobile {
    text-align: left !important;
  }
  .display-1-less {
    padding-top: 40px;
  }
  .small-header span {
    font-weight: 400;
    font-size: 25px;
  }
  .link-top {
    padding: 0px 0 30px;
    font-size: 16px;
    text-align: center !important;
  }
  .accordion-background {
    color: #000000;
    border: 1px solid #447969 !important;
    font-size: 1.15rem;
    font-weight: 300;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.505rem 0.85rem 0.605rem !important;
  }
  /* .time {
    color: #000000;
    border: 1px solid #447969 !important;
    font-size: 1.15rem;
    font-weight: 300;
    background: rgba(110, 166, 154, 0.7);
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.505rem 0.85rem 0.605rem !important;
  } */

  .navbar-brand {
    margin: 0 auto;
    text-align: center;
    float: none;
  }
  .smaller-mobile {
    padding: 0.205rem 0.65rem 0.305rem !important;
    font-size: 0.9rem;
  }
  select {
    background-image: url(../images/arrow.svg) !important;
    background-position: calc(100% - 15px) 16px;
    background-size: 18px auto;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 0.9rem;
  }
  .form-control,
  input {
    font-family: "Lato", serif;
    font-weight: 300 !important;
    color: #000;
    font-size: 0.9rem;
  }
  .less-padding {
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
